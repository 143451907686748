import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./style/step1.module.css";
import Slider from "./slider";
import { Packages } from "../main";
import { useContext } from "react";
// import { Spin } from "antd";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

const override = {
  display: "block",
  margin: "30px auto 0px",
  borderColor: "blue",
};

const Step1 = (props) => {
  function packagesdetails() {
    // const axios = require("axios");

    // let config = {
    //   method: "get",
    //   url: "https://dev.nepaltelehealth.com" + "api/v1/standard_package",
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: "Token " + process.env.REACT_APP_OFFICE_TOKEN,
    //   },
    // };

    axios
      .get("https://dev.nepaltelehealth.com/api/v1/get_standard_package/")
      .then((response) => {
        setposts(response.data);
        console.log(response.data);
        setprocessing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    packagesdetails();
  }, []);
  const [posts, setposts] = useState();
  const [processing, setprocessing] = useState(true);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [state, actions] = useContext(Packages);
  console.log(state);
  const Navigate = useNavigate();
  const onSubmit = (data) => {
    console.log(data);
    let c = null;
    for (let i = 0; i < posts.length; i++) {
      if (posts[i].id === data.pid) {
        c = posts[i];
        break;
      }
    }
    console.log(c);
    // actions({ ...state, ...c });
    state.change_my_plan
      ? Navigate("/packages/results")
      : Navigate("/packages/step2");
    actions({ ...state, ...c, change_my_plan: false });
  };

  let content =
    posts &&
    state.terms === "one_time" ? 
    posts
      ?.filter((item) => item.membership_type === "ONE_TIME")
      .map((x) => (
    <div style={{}} className="col p-2 m-0" key={x.id}>
      <input
            className="checkoutinput"
            hidden
            {...register("pid", { required: true })}
            type="radio"
            value={x.id}
            id={x.id}
            defaultChecked={state.id === x.id}
          />
          <label
            style={{ border: "1px solid #d0dbf8" }}
            className="Package checkoutlabel "
            htmlFor={x.id}
          >
            <div
              className="w-100 px-5 py-2 text-center text-white fw-bold"
              style={{
                backgroundColor: "var(--tg-blue)",
                borderRadius: "20px 20px 0px 0px",
                letterSpacing: "2px",
              }}
            >
                One Time Plan
            </div>

            <div className="mt-2 p-0 text-center" style={{ fontSize: "30px" }}>
              $ {x.package_tiers?.[0]?.yearly_retail_price}
              <span className="fs-6">
                /Visit
              </span>
            </div>
            <div className="text-center fw-bold fs-5">
              Visit Count: {x.visit_count}
            </div>
            <div className="text-center fw-bold text-uppercase  fs-5">
              {" "}
              {x.title}
            </div>
          </label>
    </div>
      ))
    :
    posts
      ?.filter((item) => item.membership_type !== "ONE_TIME")
      .map((x) => (
        <div style={{}} className="col p-2 m-0" key={x.id}>
          <input
            className="checkoutinput"
            hidden
            {...register("pid", { required: true })}
            type="radio"
            value={x.id}
            id={x.id}
            defaultChecked={state.id === x.id}
          />
          <label
            style={{ border: "1px solid #d0dbf8" }}
            className="Package checkoutlabel "
            htmlFor={x.id}
          >
            <div
              className="w-100 px-5 py-2  text-white fw-bold"
              style={{
                backgroundColor: "var(--tg-blue)",
                borderRadius: "20px 20px 0px 0px",
                letterSpacing: "2px",
              }}
            >
              {state.terms === "monthly" ? "Monthly Plan" : "Yearly Plan"}
            </div>

            <div className="mt-2 p-0 text-center" style={{ fontSize: "30px" }}>
              $
              {state.terms === "yearly" ? (
                <>
                  {Number(
                    (
                      x.package_tiers?.[0]?.yearly_retail_price -
                      (x.package_tiers?.[0]?.yearly_retail_price % 1)
                    ).toFixed(0)
                  )}
                  <sup className="">
                    .
                    {Number(
                      (x.package_tiers?.[0]?.yearly_retail_price % 1) * 100
                    ) === 0
                      ? "00"
                      : Number(
                          (
                            (x.package_tiers?.[0]?.yearly_retail_price % 1) *
                            100
                          ).toFixed(0)
                        )}
                  </sup>
                </>
              ) : (
                <>
                  {Number(
                    (
                      x.package_tiers?.[0]?.yearly_retail_price / 12 -
                      ((x.package_tiers?.[0]?.yearly_retail_price / 12) % 1)
                    ).toFixed(0)
                  )}
                  <sup className="">
                    .
                    {Number(
                      ((x.package_tiers?.[0]?.yearly_retail_price / 12) % 1) *
                        100
                    ) === 0
                      ? "00"
                      : Number(
                          (
                            ((x.package_tiers?.[0]?.yearly_retail_price / 12) %
                              1) *
                            100
                          ).toFixed(0)
                        )}
                  </sup>
                </>
              )}
              <span className="fs-6">
                {state.terms === "monthly" ? "/MO" : "/YR"}
              </span>
            </div>
            <div className="text-center fw-bold text-uppercase  fs-5">
              {" "}
              {x.title}
            </div>
          </label>
        </div>
      ));
  return (
    <div style={{ background: "" }} className=" mt-5 m-auto">
      <form
        style={{ borderRadius: "29px", border: "1px solid #d0dbf8" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          style={{
            backgroundColor: "var(--tg-blue)",
            borderRadius: "29px 29px 0px 0px",
            letterSpacing: "1px",
          }}
          className=" text-center text-white p-3"
        >
          <span className="fw-bold fs-4 float-start"> 1</span>
          <span className="text-center fw-bold fs-4">Choose a package</span>
        </div>
        <div>
          <div className="bg-light p-5">
            <div className="">
              <Slider />
            </div>
            <div className="row">
              {processing ? (
                <div style={{ height: 200, margin: "auto" }}>
                  {/* <Spin size="large" style={{ margin: 100 }} /> */}
                  <ClipLoader
                    color={"blue"}
                    loading={true}
                    cssOverride={override}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                content
              )}
            </div>

            <div className="text-danger fw-bold">
              {errors.Packagename?.type === "required" &&
                "⚠ Please Choose A Package"}
            </div>
            <div className="container w-75 pt-5">
              <input className="checkoutinput" type="submit" value="Next" />
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "var(--tg-blue)",
            letterSpacing: "2px",
            borderRadius: "0px 0px 29px 29px",
          }}
          className=" stepsbg text-center"
        >
          <div className="fs-4  p-3 ">
            <strong className="float-start">2</strong>Customer Information
            <div className="border border-bottom rounded mt-4 w-50 m-auto"></div>
          </div>
          <div
            style={{
              backgroundColor: "var(--tg-blue)",
              letterSpacing: "2px",
              borderRadius: "0px 0px 29px 29px",
            }}
            className="stepsbg fs-4  p-3"
          >
            <strong className="float-start">3</strong>
            Review Information
          </div>
        </div>
      </form>
    </div>
  );
};

export default Step1;
