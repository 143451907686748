// import { notification } from "antd";
import axios from "axios";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
// import { useNavigate } from "react-router-dom";
import { API } from "../../config/urls";
// import { imgconvert } from "../../helpers/imgconverter";
// import { FaArrowLeft, FaGreaterThan } from "react-icons/fa";
import toast from "react-hot-toast";
// import Layout from "../../layouts/Layout";

export default function Offisignup() {
  // const navigate = useNavigate();
  const searchQuery = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  // const [step, setstep] = useState(2);
  // const [mapdata, setmapdata] = useState();
  const [officeType, setOfficeType] = useState();
  const [degreeList, setDegreeList] = useState();
  const [plans, setPlans] = useState();
  const [data, setdata] = useState({
    type: null,
    name: searchQuery.get("name"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // const openNotification = () => {
  //   notification.open({
  //     message: "Thank you for getting in touch!",
  //     description:
  //       "We appreciate you contacting us. One of our team member will get back in touch with you soon! Have a great day!",
  //   });
  // };

  // const doctorSpecialityOptions = [
  //   {value: "General",label: "General"},
  //   {value: "Dermatologist",label: "Dermatologist"},
  //   {value: "Cardiologist",label: "Cardiologist"},
  //   {value: "ENT",label: "ENT"},
  //   {value: "Neurologist",label: "Neurologist"},
  //   {value: "Orthopedics",label: "Orthopedics"},
  //   {value: "Gynaecologist",label: "Gynaecologist"},
  // ]

  // const labSpecialityOptions = [
  //   {value: "General",label: "General"},
  //   {value: "Microbiology",label: "Microbiology"},
  //   {value: "Histology",label: "Histology"},
  //   {value: "Hematology",label: "Hematology"},
  //   {value: "Immunology",label: "Immunology"},
  //   {value: "Clinical Chemistry",label: "Clinical Chemistry"},
  // ]

  const [doctorSpecialityOptions, setDoctorSpecialityOptions] = useState([]);
  const [labSpecialityOptions, setLabSpecialityOptions] = useState([]);

  useEffect(() => {
    axios(API.getBusinessType).then((x) => {
      // setmapdata(x.data);
      setdata((prevData) => ({
        ...prevData,
        type: x.data?.filter((x) => x.name === searchQuery.get("name"))?.[0]
          ?.id,
      }));
    });
    axios(API.getOfficeType).then((x) => {
      setOfficeType(x.data);
    });
    axios(API.getDegreeList).then((x) => {
      setDegreeList(x.data);
    });
    axios(API.getSpecialityList).then((x) => {
      const newDataDoctor = x?.data
        ?.filter((x) => x.user_type === "doctor")
        ?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      const newDataLab = x?.data
        ?.filter((x) => x.user_type === "lab")
        ?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      setDoctorSpecialityOptions(newDataDoctor);
      setLabSpecialityOptions(newDataLab);
    });
    axios(API.getPlans).then((x) => {
      console.log(x.data);

      setPlans(x.data);
    });
  }, [searchQuery]);

  const onSubmit = (x) => {
    console.log(x);
    const formData = new FormData();
    formData.append("address1", x.address1);
    formData.append("address2", x.address2);
    formData.append("city", x.city);
    formData.append("email", x.email);
    formData.append("first_name", x.first_name);
    formData.append("last_name", x.last_name);
    formData.append("phone_number", x.phone_number);
    formData.append("state", x.state);
    formData.append("zip_code", x.zip_code);
    formData.append("business_category_id", data.type);
    formData.append("signup_form", true);
    formData.append("message", "It is signup form.");
    formData.append("subject", "It is signup form.");
    x.external_id && formData.append("external_id", x.external_id);
    x.licensed_number && formData.append("licensed_number", x.licensed_number);
    x.additional_note && formData.append("additional_note", x.additional_note);
    x.signature && formData.append("signature", x.signature?.[0]);
    x.speciality && formData.append("speciality", x.speciality);
    x.office_name && formData.append("office_name", x.office_name);
    x.office_type && formData.append("office_type", x.office_type);
    x.social_security_number &&
      formData.append("social_security_number", x.social_security_number);
    x.board_certification &&
      formData.append("board_certification", x.board_certification);
    x.board_certified && formData.append("board_certified", x.board_certified);
    x.degree && formData.append("degree", x.degree);
    x.licensed_state && formData.append("licensed_state", x.licensed_state);
    x.what_describe_you &&
      formData.append("what_describe_you", x.what_describe_you);

    axios
      .post(API.postContact, formData)
      .then(() => {
        toast.success("Signup Form Submitted Successfully");
        reset();
        // navigate("/");
      })
      .catch(() => {
        toast.error("Form not Submitted");
        reset();
      });
  };

  return (
    <>
      {/* <Layout header={1} footer={1}> */}
      <div
        style={{ paddingLeft: "10vw", paddingRight: "10vw", paddingTop: 40 }}
      >
        <div className="row p-4">
          <div className="col-md-8 ">
            <>
              <div
                style={{ maxWidth: 700, margin: "auto" }}
                className="col container shadow py-2 my-3 m-auto rounded p-5"
              >
                <h3 className="fw-bold p-4">Signup for {data.name}</h3>
                {data.name === "Distributor" ||
                data.name === "Reseller" ||
                data.name === "Office" ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6>{data.name} Info</h6>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder={data.name + " Name"}
                          {...register("office_name", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">Please enter Name</div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <select
                          className="form-control form-control-lg text-start cursor-pointer"
                          {...register("office_type")}
                        >
                          <option style={{ fontSize: "12px" }}>
                            Select Office Type
                          </option>
                          {officeType?.map((x, y) => {
                            return (
                              <option key={y} value={x.id}>
                                {x.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.office_type && (
                          <div className="text-danger">
                            Please Select Office Type
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Address 1"
                          {...register("address1", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Address 1
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Address 2"
                          {...register("address2", {
                            maxLength: 80,
                          })}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Zip"
                          {...register("zip_code", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Zip Code
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Province"
                          {...register("state", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Province
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="City"
                          {...register("city", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">Please enter City</div>
                        )}
                      </div>
                    </div>
                    <h6 style={{ marginTop: 40 }}>Account Detail</h6>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="First Name"
                          {...register("first_name", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        {errors.first_name && (
                          <div className="text-danger">
                            Please enter First Name
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Last Name"
                          {...register("last_name", {
                            required: true,
                            maxLength: 100,
                          })}
                        />
                        {errors.last_name && (
                          <div className="text-danger">
                            Please enter Last Name
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Email"
                          {...register("email", {
                            required: true,
                            maxLength: 100,
                            pattern:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <div className="text-danger">Please enter Email</div>
                        )}
                        {errors.email?.type === "pattern" && (
                          <div className="text-danger">
                            Please enter a valid Email
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Phone Number"
                          {...register("phone_number", { maxLength: 12 })}
                        />
                        {errors.mobile_number?.type === "maxLength" && (
                          <div className="text-danger">
                            Please enter a valid Phone Number
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="text-end">
                      {/* <button
                          onClick={() => {
                            setstep(0);
                            reset()
                          }}
                          className="learnmorebtn btn me-3"
                          type="button"
                        >
                          <FaArrowLeft />
                        </button> */}
                      <button type="submit" className=" btn">
                        Submit
                      </button>
                    </div>
                  </form>
                ) : data.name === "Doctor" ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6>{data.name} Info</h6>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="First Name"
                          {...register("first_name", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        {errors.first_name && (
                          <div className="text-danger">
                            Please enter First Name
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Last Name"
                          {...register("last_name", {
                            required: true,
                            maxLength: 100,
                          })}
                        />
                        {errors.last_name && (
                          <div className="text-danger">
                            Please enter Last Name
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Email"
                          {...register("email", {
                            required: true,
                            maxLength: 100,
                            pattern:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <div className="text-danger">Please enter Email</div>
                        )}
                        {errors.email?.type === "pattern" && (
                          <div className="text-danger">
                            Please enter a valid Email
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Phone Number"
                          {...register("phone_number", { maxLength: 12 })}
                        />
                        {errors.mobile_number?.type === "maxLength" && (
                          <div className="text-danger">
                            Please enter a valid Phone Number
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Address 1"
                          {...register("address1", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Address 1
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Address 2"
                          {...register("address2", {
                            maxLength: 80,
                          })}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Zip"
                          {...register("zip_code", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Zip Code
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Province"
                          {...register("state", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Province
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="City"
                          {...register("city", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">Please enter City</div>
                        )}
                      </div>
                    </div>

                    <h6 style={{ marginTop: 40 }}>Detail Info</h6>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Liscense Number"
                          {...register("licensed_number")}
                        />
                      </div>
                      <div className="col-md-6">
                        <div
                          style={{
                            display: "flex",
                            paddingLeft: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="form-control-lg text-start"
                        >
                          <label
                            style={{ width: "50%" }}
                            htmlFor="board_certified"
                            className="text-start"
                          >
                            Board Certified
                          </label>
                          <input
                            type="checkbox"
                            style={{ height: "20px" }}
                            className="text-start"
                            placeholder="Board Certified"
                            {...register("board_certified")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        {/* <input
                            type="text"
                            className="form-control form-control-lg text-start cursor-default"
                            placeholder="Speciality"
                            {...register("speciality")}
                          /> */}
                        <select
                          className="form-control form-control-lg text-start cursor-pointer"
                          {...register("speciality")}
                        >
                          <option style={{ fontSize: "12px" }}>
                          Professional Specialty 
                          </option>
                          {doctorSpecialityOptions?.map((x, y) => {
                            return (
                              <option key={y} value={x.value}>
                                {x.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <select
                          className="form-control form-control-lg text-start cursor-pointer"
                          {...register("degree")}
                        >
                          <option style={{ fontSize: "12px" }}>
                            Select Degree
                          </option>
                          {degreeList?.map((x, y) => {
                            return (
                              <option key={y} value={x.id}>
                                {x.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.degree && (
                          <div className="text-danger">
                            Please Select Degree
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <select
                          className="form-control form-control-lg text-start cursor-pointer"
                          {...register("what_describe_you")}
                        >
                          <option style={{ fontSize: "12px" }}>
                            Which service are you interested in?
                          </option>
                          {plans?.map((item) => (
                            <option key={item?.id} value={item?.plan_code}>
                              {item?.plan_name}
                            </option>
                          ))}
                        </select>
                        {errors.office_type && (
                          <div className="text-danger">
                            Please Which service are you interested in?
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <textarea
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Please provide a short note about yourself"
                          {...register("additional_note")}
                        />
                      </div>
                    </div>

                    <div className="text-end">
                      {/* <button
                          onClick={() => {
                            setstep(0);
                            reset()
                          }}
                          className="learnmorebtn btn me-3"
                          type="button"
                        >
                          <FaArrowLeft />
                        </button> */}
                      <button type="submit" className=" btn">
                        Submit
                      </button>
                    </div>
                  </form>
                ) : data.name === "Lab" ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6>{data.name} Info</h6>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="First Name"
                          {...register("first_name", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        {errors.first_name && (
                          <div className="text-danger">
                            Please enter First Name
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Last Name"
                          {...register("last_name", {
                            required: true,
                            maxLength: 100,
                          })}
                        />
                        {errors.last_name && (
                          <div className="text-danger">
                            Please enter Last Name
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Email"
                          {...register("email", {
                            required: true,
                            maxLength: 100,
                            pattern:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <div className="text-danger">Please enter Email</div>
                        )}
                        {errors.email?.type === "pattern" && (
                          <div className="text-danger">
                            Please enter a valid Email
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Phone Number"
                          {...register("phone_number", { maxLength: 12 })}
                        />
                        {errors.mobile_number?.type === "maxLength" && (
                          <div className="text-danger">
                            Please enter a valid Phone Number
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Address 1"
                          {...register("address1", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Address 1
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Address 2"
                          {...register("address2", {
                            maxLength: 80,
                          })}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Zip"
                          {...register("zip_code", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Zip Code
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Province"
                          {...register("state", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Province
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="City"
                          {...register("city", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">Please enter City</div>
                        )}
                      </div>
                    </div>

                    <h6 style={{ marginTop: 40 }}>Detail Info</h6>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="External Id"
                          {...register("external_id", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        {errors.first_name && (
                          <div className="text-danger">
                            Please enter External Id
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Licensed Number"
                          {...register("licensed_number")}
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        {/* <input
                            type="text"
                            className="form-control form-control-lg text-start cursor-default"
                            placeholder="Speciality"
                            {...register("speciality")}
                          /> */}
                        <select
                          className="form-control form-control-lg text-start cursor-pointer"
                          {...register("speciality")}
                        >
                          <option style={{ fontSize: "12px" }}>
                          Professional Specialty 
                          </option>
                          {labSpecialityOptions?.map((x, y) => {
                            return (
                              <option key={y} value={x.value}>
                                {x.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="signature">Signature</label>
                        <input
                          type="file"
                          style={{ width: "100%" }}
                          className="text-start"
                          placeholder="signature"
                          {...register("signature")}
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <textarea
                          type="number"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Additional Notes"
                          {...register("additional_note")}
                        />
                      </div>
                    </div>

                    <div className="text-end">
                      {/* <button
                          onClick={() => {
                            setstep(0);
                            reset()
                          }}
                          className="learnmorebtn btn me-3"
                          type="button"
                        >
                          <FaArrowLeft />
                        </button> */}
                      <button type="submit" className=" btn">
                        Submit
                      </button>
                    </div>
                  </form>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h6>{data.name} Info</h6>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="First Name"
                          {...register("first_name", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        {errors.first_name && (
                          <div className="text-danger">
                            Please enter First Name
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Last Name"
                          {...register("last_name", {
                            required: true,
                            maxLength: 100,
                          })}
                        />
                        {errors.last_name && (
                          <div className="text-danger">
                            Please enter Last Name
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Email"
                          {...register("email", {
                            required: true,
                            maxLength: 100,
                            pattern:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <div className="text-danger">Please enter Email</div>
                        )}
                        {errors.email?.type === "pattern" && (
                          <div className="text-danger">
                            Please enter a valid Email
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Phone Number"
                          {...register("phone_number", { maxLength: 12 })}
                        />
                        {errors.mobile_number?.type === "maxLength" && (
                          <div className="text-danger">
                            Please enter a valid Phone Number
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Address 1"
                          {...register("address1", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Address 1
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Address 2"
                          {...register("address2", {
                            maxLength: 80,
                          })}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Zip"
                          {...register("zip_code", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Zip Code
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Province"
                          {...register("state", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">
                            Please enter Province
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="City"
                          {...register("city", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.office_name && (
                          <div className="text-danger">Please enter City</div>
                        )}
                      </div>
                    </div>

                    <h6 style={{ marginTop: 40 }}>Detail Info</h6>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Medical Liscense No."
                          {...register("medical_liscense_number", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        {errors.first_name && (
                          <div className="text-danger">
                            Please enter Medical Liscense No.
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="National Provider Identifier"
                          {...register("national_provider_identifier")}
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="Board Certification"
                          {...register("board_certification")}
                        />
                      </div>
                      <div className="col-md-6">
                        <div
                          style={{
                            display: "flex",
                            paddingLeft: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="form-control-lg text-start"
                        >
                          <label
                            style={{ width: "50%" }}
                            htmlFor="board_certified"
                            className="text-start"
                          >
                            Board Certified
                          </label>
                          <input
                            type="checkbox"
                            style={{ height: "20px" }}
                            className="text-start"
                            placeholder="Board Certified"
                            {...register("board_certified")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <input
                          type="number"
                          className="form-control form-control-lg text-start cursor-default"
                          placeholder="DEA Number"
                          {...register("dea_number")}
                        />
                      </div>
                    </div>

                    <div className="text-end">
                      {/* <button
                          onClick={() => {
                            setstep(0);
                            reset()
                          }}
                          className="learnmorebtn btn me-3"
                          type="button"
                        >
                          <FaArrowLeft />
                        </button> */}
                      <button type="submit" className=" btn">
                        Submit
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </>
            {/* )} */}
          </div>
          <div className="col overflow-hidden">
            <img
              height={500}
              className={""}
              src="/img/telehealth/joinus.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* </Layout> */}
    </>
  );
}
