import { useEffect, useState } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import HomeOne from "./pages/Homes/HomeOne";
import WOW from "wow.js";
import AOS from "aos";
// import HomeTwo from "./pages/Homes/HomeTwo";
// import HomeThree from "./pages/Homes/HomeThree";
// import HomeFour from "./pages/Homes/HomeFour";
// import AboutUsPage from "./pages/AboutUsPage";
// import ServicesPageOne from "./pages/Services/ServicesPageOne";
// import ServicesPageTwo from "./pages/Services/ServicesPageTwo";
// import ServicesDetailsPage from "./pages/Services/ServicesDetailsPage";
// import ProjectPageOne from "./pages/Projects/ProjectPageOne";
// import ProjectPageTwo from "./pages/Projects/ProjectPageTwo";
// import ProjectDetailsPage from "./pages/Projects/ProjectDetailsPage";
// import TeamPage from "./pages/TeamPages/TeamPage";
// import EstimatePage from "./pages/EstimatePage";
// import ShopPage from "./pages/ShopPages/ShopPage";
// import ShopDetailsPage from "./pages/ShopPages/ShopDetailsPage";
// import BlogPage from "./pages/BlogPages/BlogPage";
// import BlogListPage from "./pages/BlogPages/BlogListPage";
// import BlogDetailsPage from "./pages/BlogPages/BlogDetailsPage";
// import ContactPage from "./pages/ContactPage";

import TeamDetailsPage from "./pages/TeamPages/TeamDetailsPage";
// new route import
// import AboutMain from '../src/pages/about/main';
// import Ourprovidersmain from '../src/pages/ourproviders/main';
// import ServicesMain from '../src/pages/service/main';
// import Howitworksmain from '../src/pages/howitworks/main';
// import Faqmain from "./pages/faqs/main";
// import ContactMain from "./pages/contact/main";
// import Whatwetreat from "./pages/Whatwetreat/main";
// import Whowetreat from "./pages/whowetreat/main";
// import Offisignup from "./pages/officeform";
// import Pricing from "./pages/pricing/main";
import Check from "./pages/checkout/main";
// import Accontcreatedmain from "./pages/account-created";
import axios from 'axios'
import { API } from "./config/urls";
// import BundlePage from "./pages/bundlepage";
import {Toaster} from "react-hot-toast"
import DynamicPage from "./pages/dynamic/main";
import Accontcreatedmain from "./pages/account-created";

function App() {
  // wow js scroll
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false, 
      live: true,
    });
    wow.init();
  }, []);

  // aos scroll
  useEffect(() => {
    AOS.init({ duration: 1000, mirror: true, once: true, disable: "mobile" });
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  // const [packages, setpackages] = useState([]);
  // useEffect(() => {
  //   axios(API.getpackages).then(function response(response) {
  //     setpackages(response.data);
  //     console.log(response.data);
  //   });
  // }, []);


  const [data,setData] = useState()
  const Getnav = () => {
    var config = {
      method: "get",
      url: API.getdocs,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        setData(response.data?.content_urls);
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    Getnav();
  }, []);

  return (
    <>
      {/* <AnimatedCursor
        innerSize={10}
        outerSize={32}
        color="0, 124, 251"
        outerAlpha={0}
        innerScale={0}
        outerScale={0}
        trailingSpeed={1000}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "option",
          "textarea",
          "button",
          ".link",
          ".swiper-pagination-bullet",
        ]}
      /> */}
<Toaster
  position="top-right"
  reverseOrder={false}
/>
      <Routes>
        <Route path="/" element={<HomeOne />} />
        {/* <Route path="/home-2" element={<HomeTwo />} />
        <Route path="/home-3" element={<HomeThree />} />
        <Route path="/home-4" element={<HomeFour />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/services" element={<ServicesPageOne />} />
        <Route path="/services-2" element={<ServicesPageTwo />} />
        <Route path="/services-details" element={<ServicesDetailsPage />} />
        <Route path="/project" element={<ProjectPageOne />} />
        <Route path="/project-two" element={<ProjectPageTwo />} />
        <Route path="/project-details" element={<ProjectDetailsPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/estimate" element={<EstimatePage />} />
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/shop-details" element={<ShopDetailsPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog-list" element={<BlogListPage />} />
        <Route path="/blog-details" element={<BlogDetailsPage />} /> */}


        <Route path="/doctor-details/:id" element={<TeamDetailsPage />} />

        {/* <Route path="/contact" element={<ContactPage />} />
        <Route path="/abouttelehealth" element={<AboutMain />} />
        <Route path="/ourproviders" element={<Ourprovidersmain />} />
        <Route path="/services" element={<ServicesMain />} />
        <Route path="/howitworks" element={<Howitworksmain />} />
        <Route path="/faqs" element={<Faqmain />} />
        <Route  path="/contact" element={<ContactMain />} />
        <Route path="/whatweserve" element={<Whatwetreat />} />
        <Route path="/whoweserve" element={<Whowetreat />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/officesignup" element={<Offisignup />} /> */}
        <Route
          exact
          path="/account-created"
          element={<Accontcreatedmain />}
        />

        <Route path="/packages/*" element={<Check />} />
        {/* {packages?.block?.map((x, y) => {
            return (
              <Route
                key={x.id}
                path={x.heading.toLowerCase().replace(/\s/g, "")}
                element={<BundlePage />}
              />
            );
          })} */}

        {/* <Route
          path="urgentcare"
          element={<BundlePage />}
        /> */}


        {data && Object.keys(data)?.map(item => {
          console.log(item)
            return (
              <Route
                path={item?.toLowerCase().replace(/\s/g, "-")}
                element={<DynamicPage />}
              />
            );
})}

      </Routes>
    </>
  );
}

export default App;
