// const URL = process.env.REACT_APP_APPURLMAIN;
const URL = "https://dev.nepaltelehealth.com"

const localURL = "https://dev.nepaltelehealth.com";

const URLs = URL + "/api/";
const frontUrl = localURL + "/api/v1/cms/";
const addon = "?populate=deep,10";

const API = {
  gethome: URLs + "index" + addon,
  gethowitworks: frontUrl + "content/how-it-works/",
  getheader: frontUrl + "header/",
  getdocs: frontUrl + "docs/",
  getfooter: frontUrl + "footer/",
  getpackages: frontUrl + "content/packages/",
  getabout: frontUrl + "content/about-telehealth/",
  getour_providers: frontUrl + "content/our-provider/",
  getwhy_us: frontUrl + "content/why-us/",
  getvirtual: URLs + "virtualprimarycare" + addon,
  getwhat_we_treat: frontUrl + "content/what-we-do/",
  getwho_we_serve: frontUrl + "content/who-we-serve/",
  getservices: frontUrl + "content/services/",
  getcontact: frontUrl + "content/contact-us/",
  getreviews: URLs + "reviews" + addon,
  getoffice_signup: URLs + "office-signup" + addon,
  checkemail: URLs + "external/email_checker",
  addformdata: frontUrl + "form-datas/",
  getpricing: URLs + "pricing" + addon,
  getallprices: URLs + "external/getpackages/",
  getindvprice: URLs + "external/getindvpackages/",
  getusertype: frontUrl + "content/usertype/",

  getBanner: frontUrl + "block/healthcare-with-a-modern-touch/",
  getfaqs: frontUrl + "block/faqs/",
  getWhoMakesUs: frontUrl + "block/who-makes-us",
  getservicesBlock: frontUrl + "block/service",

  getStandardPackage: localURL + "/api/v1/get_standard_package/",
  getDoctorList: localURL + '/api/v1/cms/doctors/',
  getBusinessType: localURL + "/api/v1/business-categories/",
  getOfficeType : localURL + "/api/v1/cms/office-types/",
  getLiscensedList: localURL + "/api/v1/cms/liscensed_state/",
  getDegreeList: localURL + "/api/v1/cms/degree/",
  getSpecialityList: localURL + "/api/v1/speciality/cms/speciality/",
  getPlans: localURL + "/api/v1/cms/plans/",


  postContact: localURL + "/api/v1/contactus/",
  postAppointment : localURL + "/api/v1/create-appointment/",
  postNewsLetter: localURL + "/api/v1/newsletter/create/",
};

export { API, URL, URLs ,localURL};

